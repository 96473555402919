import React, { useEffect, useState, useRef } from 'react';
import { Spin, Space, Row, Col, Card, Button, notification, Select, Modal, List } from 'antd';
import CertificateModal from './certificateModal';
import axios from 'axios';
import _ from 'underscore';
import setting from '../../config/setting';
import moment from 'moment';
import '../style/style.css';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { FolderOpenTwoTone, WarningTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;
//const { Option } = Select;
//const FormItem = Form.Item;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'driver_qualifications.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const DriverQualification = (props) => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [driverResponse, setDriverResponse] = useState([]);
  const [driverAll, setDriverAll] = useState([]);
  const [change, setChange] = useState(false);
  const [driverEvent, setDriverEvent] = useState([]);
  const [driverAllLength, setDriverAllLength] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  const [errMsg, setErrMsg] = useState('');
  const [hasPermission, setHasPermission] = useState(false);
  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [typecheckValue, setTypeCheckValue] = useState('');
  const [activeDrivers, setActiveDrivers] = useState(0);
  const [alertDrivers, setAlertDrivers] = useState(0);
  const [warningDrivers, setWarningDrivers] = useState(0);
  const [isCertModalVisible, setIsCertModalVisible] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertType, setSelectedCertType] = useState('');

  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const filterRefCounter = useRef(null);

  useEffect(() => {
    getData();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, []);

  let theGrids = theGrid.current;
  let theSearchs = theSearch.current;
  if (theGrids && theSearchs) {
    theSearchs.control.grid = theGrids.control;
    console.log('the searches', (theSearchs.grid = theGrids));
  }

  function downloadCert(e, attachment) {
    setLoader(true);
    setIsLoaderActive(true);
    axios
      .post(setting.serverUrl + '/api/downloadVaccineCertificate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          attachment_detail: [attachment]
        }
      })
      .then((response) => {
        window.open(response.data && response.data.length > 0 && response.data[0].url);
      });
  }

  function getData() {
    setNoRecordFound(true);
    setIsLoading(true);
    axios
      .post(setting.serverUrl + '/api/getEmployeeCustomerDriverQualification', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          company: localStorage.getItem('customerId'),
          customerId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        setIsLoading(false);
        setChange(!change);
        if (response && response.status == 200) {
          let finalResult = response?.data;
          let groupById = _.groupBy(finalResult, 'employee');
          let groupedByIdData = parseGroupRecords(groupById);
       
          groupedByIdData.sort((a, b) => {
            const dateA = new Date(a.attachment_ids?.CDL?.cert_exp);
            const dateB = new Date(b.attachment_ids?.CDL?.cert_exp);
            return dateB - dateA;
          });
console.log("groupedByIdData===========",groupedByIdData)
          let resultNew = groupedByIdData.map((k) => {
            return {
              ...k,
              child_ids:
                k.child_ids.length > 0 &&
                !k.child_ids[1].includes('|') &&
                !k.child_ids[1].includes(',')
                  ? k.child_ids[1]
                  : k.child_ids[1]?.split('|')[1] || k.child_ids[1]?.split(',')[1],
              certDocs: k.certDocs && k.certDocs.length > 0 ? k.certDocs : '',
              emp_status:
                k.certDocs &&
                k.certDocs.length > 0 &&
                k.certDocs[0].emp_status != null &&
                k.certDocs[0].emp_status != ''
                  ? k.certDocs[0].emp_status
                  : '',
              mcExp:
                k.attachment_ids?.DOTMC?.cert_exp != false
                  ? k.attachment_ids?.DOTMC?.cert_exp
                  : null,
              mcExpForExcel:
                k.attachment_ids?.DOTMC?.cert_exp != false &&
                k.attachment_ids?.DOTMC?.cert_exp != null
                  ? moment(k.attachment_ids?.DOTMC?.cert_exp).format('MM/DD/YYYY')
                  : null,
              mcExpDocId:
                k.attachment_ids?.DOTMC?.attachment_id != null &&
                k.attachment_ids?.DOTMC?.attachment_id,

              covExp:
                k.attachment_ids?.DCOVAR?.cert_exp != false &&
                k.attachment_ids?.DCOVAR?.cert_exp != null
                  ? moment(k.attachment_ids?.DCOVAR?.cert_exp).format('MM/DD/YYYY')
                  : null,
              covExpDocId:
                k.attachment_ids?.DCOVAR?.attachment_id != null &&
                k.attachment_ids?.DCOVAR?.attachment_id,

              mvrExp:
                k.attachment_ids?.AMVR?.cert_exp != false &&
                k.attachment_ids?.AMVR?.cert_exp != null
                  ? moment(k.attachment_ids?.AMVR?.cert_exp).format('MM/DD/YYYY')
                  : null,
              mvrExpDocId:
                k.attachment_ids?.AMVR?.attachment_id != null &&
                k.attachment_ids?.AMVR?.attachment_id,

              dlExp:
                k.attachment_ids?.CDL?.cert_exp != false ? k.attachment_ids?.CDL?.cert_exp : null,
              dlExpForExcel:
                k.attachment_ids?.CDL?.cert_exp != false && k.attachment_ids?.CDL?.cert_exp != null
                  ? moment(k.attachment_ids?.CDL?.cert_exp).format('MM/DD/YYYY')
                  : null,
              dlExpDocId:
                k.attachment_ids?.CDL?.attachment_id != null &&
                k.attachment_ids?.CDL?.attachment_id,

              newDL:
                k.certDocs && k.certDocs.length > 0 && k.certDocs[0].DL != false
                  ? k.certDocs[0].DL
                  : '',
              CDLISDocId:
                k.attachment_ids?.CDLIS?.attachment_id != null &&
                k.attachment_ids.CDLIS.attachment_id,
              DADocId:
                k.attachment_ids?.DA?.attachment_id != null && k.attachment_ids?.DA?.attachment_id,
              DOTCDocId:
                k.attachment_ids?.DOTC?.attachment_id != null &&
                k.attachment_ids.DOTC.attachment_id,
              IDRMVRDocId:
                k.attachment_ids?.IDRMVR?.attachment_id != null &&
                k.attachment_ids.IDRMVR.attachment_id,
              PEIDocId:
                k.attachment_ids?.PEI?.attachment_id != null && k.attachment_ids.PEI.attachment_id,
              RTFCDocId:
                k.attachment_ids?.RTFC?.attachment_id != null &&
                k.attachment_ids.RTFC.attachment_id,
              PSPDocId:
                k.attachment_ids?.PSP?.attachment_id != null && k.attachment_ids.PSP.attachment_id,
              ELDTCDocId:
                k.attachment_ids?.ELDTC?.attachment_id != null &&
                k.attachment_ids.ELDTC.attachment_id,
              LCVTCDocId:
                k.attachment_ids?.LCVTC?.attachment_id != null &&
                k.attachment_ids.LCVTC.attachment_id
            };
          });
          let filterActiveDrivers = resultNew.filter((i) => {
            return i.emp_status == 'active';
          });
          setActiveDrivers(filterActiveDrivers.length || 0);
          const { totalAlerts, totalWarnings } = calculateAlertsAndWarningsCount(resultNew);
          setAlertDrivers(totalAlerts);
          setWarningDrivers(totalWarnings);
          setDriverResponse(resultNew);
          // setWijmoFlexGrid(resultNew, 10);
          setNoRecordFound(false);
          setHasPermission(true);
          setErrMsg('Module not activated.');
          setChange(!change);
          let total = resultNew;
          setDriverAll(total);
          setDriverAllLength(resultNew.length);
          setDriverEvent(resultNew);
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setErrMsg('Access permission not allowed.');
          setNoRecordMessage('Access Permission not allowed.');
        } else if (response.status === 202) {
          setNoRecordFound(false);
          // setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNoRecordMessage('No Record Found.');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
        }
      });
  }

  useEffect(() => {
    if (driverResponse.length > 0) {
      const collectionView1 = new CollectionView(driverResponse, {
        pageSize: driverResponse.length
      });
      setViewData(collectionView1);
      const collectionView2 = new CollectionView(driverResponse, {
        pageSize: pageSize,
        collectionChanged: (s) => {
          filterRefCounter.current = s.totalItemCount;
          var filterCounter = document.getElementById('filterCounter');
          if (filterCounter) {
            filterCounter.textContent = s.totalItemCount;
          }
        }
      });
      setView(collectionView2);
    }
  }, [driverResponse, pageSize]);
  function isExpired(dateString) {
    return new Date(dateString) < new Date();
  }

  function isExpiringWithin30Days(dateString) {
    let currentDate = new Date();
    let expirationDate = new Date(dateString);
    let thirtyDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
    return expirationDate <= thirtyDaysLater;
  }

  function calculateAlertsAndWarningsCount(response) {
    let totalAlerts = 0;
    let totalWarnings = 0;

    response.forEach((employee) => {
      let alertCount = 0;
      let warningCount = 0;

      if (
        (employee.mcExp !== null && isExpired(employee.mcExp)) ||
        (employee.mvrExp !== null && isExpired(employee.mvrExp)) ||
        (employee.covExp !== null && isExpired(employee.covExp)) ||
        (employee.dlExp !== null && isExpired(employee.dlExp))
      ) {
        alertCount = 1;
      } else if (
        (employee.mcExp !== null && isExpiringWithin30Days(employee.mcExp)) ||
        (employee.mvrExp !== null && isExpiringWithin30Days(employee.mvrExp)) ||
        (employee.covExp !== null && isExpiringWithin30Days(employee.covExp)) ||
        (employee.dlExp !== null && isExpiringWithin30Days(employee.dlExp))
      ) {
        warningCount = 1;
      }

      totalAlerts += alertCount;
      totalWarnings += warningCount;
    });
    return { totalAlerts, totalWarnings };
  }
  function parseGroupRecords(data) {
    const certTypeMapping = {
        'DOT Clearinghouse': 'DOTC',
        "CDLIS (Commercial Driver's License Information Search)": 'CDLIS',
        'PSP Search': 'PSP',
        'Previous-Employer Information': 'PEI',
        'Road Test Form and Certificate': 'RTFC',
        "Driver's Certification of Violations/Annual Review": 'DCOVAR',
        "Driver's Application": 'DA',
        "Commercial Driver's License (CDL)": 'CDL',
        'Initial Driving Record (MVR)': 'IDRMVR',
        'DOT Medical Card (Medical Examiner Certificate)': 'DOTMC',
        'Annual Motor Vehicle Report (MVR)': 'AMVR',
        'Entry-Level Driving Training Certificate': 'ELDTC',
        'Longer-combination Vehicle Training Certificate': 'LCVTC',
        'Miscellaneous': 'MISC'
    };

    return Object.values(data).reduce((acc, records) => {
        if (records.length === 0) return acc;

        const latestRecord = records.reduce((latest, record) => 
            new Date(record.create_date) > new Date(latest.create_date) ? record : latest
        );

        const allCertificates = {};
        const attachmentIds = {};

        latestRecord.certDocs.forEach(cert => {
            const shortCertType = certTypeMapping[cert.cert_type] || cert.cert_type;
            
            if (!allCertificates[shortCertType]) {
                allCertificates[shortCertType] = [];
            }
            allCertificates[shortCertType].push({
                attachment_id: cert.attachment_id,
                cert_exp: cert.cert_exp
            });

            const certDate = cert.cert_exp ? new Date(cert.cert_exp) : new Date(0);
            if (!attachmentIds[shortCertType] || certDate > new Date(attachmentIds[shortCertType].cert_exp || 0)) {
                attachmentIds[shortCertType] = {
                    attachment_id: cert.attachment_id,
                    cert_exp: cert.cert_exp
                };
            }
        });

        latestRecord.attachment_ids = attachmentIds;
        latestRecord.all_certificates = allCertificates;
        acc.push(latestRecord);

        return acc;
    }, []);
}

  function getDateOnly(date = new Date()) {
    const currentDate = date;
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const noTimeDate = new Date(currentYear, currentMonth, currentDayOfMonth, 0, 0, 0);
    return noTimeDate;
  }

  function convertIntoTimeStamp(date) {
    return date.getTime();
  }

  function getTwoDayBefore() {
    return convertIntoTimeStamp(getPreviousDay(getDateOnly()));
  }

  function getCurrentTimeStamp() {
    return convertIntoTimeStamp(getDateOnly());
  }

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 30);

    return previous;
  }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    // flexes = ctl;
    setListboxColumns(ctl.columns);

    const sender = ctl;
    sender.select(-1, -1);
  };
  const showDateWithIcon = (dateString) => {
    let gotExpDate = isExpired(dateString);
    let gotWarnDate = isExpiringWithin30Days(dateString);
    let icon = null;
    let colorStyle = {};
    if (gotExpDate) {
      icon = <WarningTwoTone twoToneColor='red' style={{ marginRight: 5 }} />;
      colorStyle = { color: 'red' };
    } else if (gotWarnDate) {
      icon = <ExclamationCircleTwoTone twoToneColor='orange' style={{ marginRight: 5 }} />;
    }

    return (
      <span className='nowrap' style={colorStyle}>
        {icon} &nbsp;
        {dateString}
      </span>
    );
  };

  const showEmail = (item) => {
    return <span className='nowrap'>{item.item.emailNew && item.item.emailNew[0]}</span>;
  };
  const showMvrExp = (item) => {
    if (item.item.mvrExp != null) {
      const element = showDateWithIcon(item.item.mvrExp);
      const allCertificates = item.item.all_certificates || {};
      const attachmentId = item.item.mvrExpDocId;
      return (
        <span className='nowrap'>
          {element}
          {attachmentId && (
            <FolderOpenTwoTone
              twoToneColor='#cc7a00'
              style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '12px' }}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   downloadCert(e, attachmentId);
              // }}
              onClick={(e) => {
                e.stopPropagation();
                // Open modal with certificates
                setSelectedCertificates(allCertificates?.AMVR);
                setSelectedCertType('Annual Motor Vehicle Report (MVR)');
                setIsCertModalVisible(true);
              }}
            />
          )}
        </span>
      );
    }
  };

  const showEmpStatus = (item) => {
    const status = item.item.emp_status?.toLowerCase();
    let color;
    let formattedStatus;

    if (status === 'terminated') {
      color = 'red';
      formattedStatus = 'Terminated';
    } else if (status === 'active') {
      color = 'green';
      formattedStatus = 'Active';
    } else {
      color = 'orange';
      formattedStatus = status?.charAt(0).toUpperCase() + status?.slice(1);
    }

    return (
      <span className='nowrap' style={{ color }}>
        {formattedStatus}
      </span>
    );
  };
  const certificateMapping = {
    DADocId: { key: 'DA', label: "Driver's Application" },
    mcExpDocId: { key: 'DOTMC', label: 'DOT Medical Card (Medical Examiner Certificate)' },
    // covExpDocId: { key: 'CDLIS', label: "Commercial Driver's License Information Search" },
    dlExpDocId: { key: 'CDL', label: "Commercial Driver's License (CDL)" },
    DOTCDocId: { key: 'DOTC', label: 'DOT Clearinghouse' },
    IDRMVRDocId: { key: 'IDRMVR', label: 'Initial Driving Record (MVR)' },
    CDLISDocId: { key: 'CDLIS', label: "Commercial Driver's License Information Search" },
    PSPDocId: { key: 'PSP', label: 'PSP Search' },
    PEIDocId: { key: 'PEI', label: 'Previous-Employer Information' },
    RTFCDocId: { key: 'RTFC', label: 'Road Test Form and Certificate' },
    ELDTCDocId: { key: 'ELDTC', label: 'Entry-Level Driving Training Certificate' },
    LCVTCDocId: { key: 'LCVTC', label: 'Longer-combination Vehicle Training Certificate' },
    MISCDOCID: { key: 'MISC', label: 'Miscellaneous' },

  };

  const showDocByType = (item, docType) => {
    const attachmentId = item.item[docType];
    const allCertificates = item.item.all_certificates || {};
    const certDetails = certificateMapping[docType];

    if (!certDetails) return null;

    return (
      <span className='nowrap'>
        {attachmentId && (
          <FolderOpenTwoTone
            twoToneColor='#cc7a00'
            style={{ fontSize: '16px', cursor: 'pointer', marginLeft: '12px' }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCertificates(allCertificates[certDetails.key]);
              setSelectedCertType(certDetails.label);
              setIsCertModalVisible(true);
            }}
          />
        )}
      </span>
    );
  };

  const showExpWithIcon = (item, expType, docType) => {
    if (item.item[expType] != null) {
      let newDate = moment(item.item[expType]).format('MM/DD/YYYY');
      const element = showDateWithIcon(newDate);
      return (
        <span className='nowrap'>
          {element}
          {showDocByType(item, docType)}
        </span>
      );
    }
  };

  const showMcExp = (item) => showExpWithIcon(item, 'mcExp', 'mcExpDocId');
  const showCovExp = (item) => showExpWithIcon(item, 'covExp', 'covExpDocId');
  const showDlExp = (item) => showExpWithIcon(item, 'dlExp', 'dlExpDocId');

  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <Row>
        <Col span={14}>
          <div className='col-12 col-md-12 col-xl-12' style={{ marginBottom: 20 }}>
            <h6 className='mt-0 mb-1' style={{ color: '#1C3A6A', fontWeight: 700, fontSize: 18 }}>
              Driver Qualifications - Employee Overview
            </h6>
          </div>
        </Col>

        <Col span={2}></Col>
        <Col span={8}></Col>
      </Row>
      <div className='row'>
        {activeDrivers != 0 && activeDrivers != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('all')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {activeDrivers}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Active <br /> Drivers
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {alertDrivers != 0 && alertDrivers != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('preEmployment')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {alertDrivers}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Alerts
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {warningDrivers != 0 && warningDrivers != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('active')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {warningDrivers}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Warnings{' '}
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
      {!noRecordFound ? (
        driverResponse && driverResponse.length > 0 ? (
          <div>
            <Card></Card>

            <Row gutter={[32, 32]}>
              <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                <FlexGridSearch
                  ref={theSearch}
                  placeholder='Search'
                  cssMatch=''
                  style={{ width: '70%', height: '40px' }}
                />
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                <div ref={fixedContainerRef}>
                  <Select
                    style={{ width: 170 }}
                    placeholder={`Items per page : ${pageSize}`}
                    onChange={paggination}
                    options={[
                      { label: 'Items per page : 10', value: 10 },
                      { label: 'Items per page : 20', value: 20 },
                      { label: 'Items per page : 50', value: 50 }
                    ]}
                    getPopupContainer={() => fixedContainerRef.current}
                  />
                </div>
              </Col>

              <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                <Button
                  type='primary'
                  size='small'
                  style={{ backgroundColor: '#3d586a', float: 'right' }}
                  disabled={isExcelPreparing}
                  onClick={exportToExcel}
                >
                  {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                </Button>
              </Col>
            </Row>

            {/* to export entire data of table */}
            <FlexGrid
              ref={theGrid}
              style={{ display: 'none' }}
              className='dummyWijmoData'
              itemsSource={viewData}
              initialized={gridInitializedData}
              formatItem={formatItem}
              isReadOnly={true}
            >
              <FlexGridColumn binding='employee[1]' header='Employee Name' width={160}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showEmpName} /> */}
              </FlexGridColumn>
              <FlexGridColumn binding='child_ids' header='Location' width={265}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showLocation} /> */}
              </FlexGridColumn>
              <FlexGridColumn binding='emailNew' header='Email' width={225}>
                <FlexGridCellTemplate cellType='Cell' template={showEmail} />
              </FlexGridColumn>
              <FlexGridColumn binding='newDL' header='DL' width={110}></FlexGridColumn>
              <FlexGridColumn binding='emp_status' header='Status' width={120}>
                <FlexGridCellTemplate cellType='Cell' template={showEmpStatus} />
              </FlexGridColumn>
              <FlexGridColumn binding='dlExp' header='DL Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showDlExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='mvrExp' header='MVR Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showMvrExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='mcExp' header='MC Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showMcExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='covExp' header='COV Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showCovExp} />
              </FlexGridColumn>
              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                // minWidth={180}
                // width='*'
                width={170}
              /> */}
              {/* <FlexGridColumn
                binding='departments'
                header='Job Position'
                // width='10*'
                minWidth={250}
                width='*'
              /> */}

              {/* <FlexGridColumn
                binding='program'
                header='Dot Mode'
                // width='10*'
                // minWidth={160}
                // width='*'
                width={100}
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn> */}
              {/* <FlexGridColumn
                binding='certDocs'
                header='Records'
                // width='12*'
                // minWidth={140}
                // width='*'
                width={90}
              >
                {/* <FlexGridCellTemplate cellType='Cell' template={showRecords} /> */}
              {/* </FlexGridColumn> */}

              <FlexGridColumn binding='docIds' header="Driver's Application" width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByDa} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'DADocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='DOT Clearinghouse' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByDot} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'DOTCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Initial Driving Record (MVR)' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByIdr} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'IDRMVRDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='CDLIs' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByCdlis} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'CDLISDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='PSP Search' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByPsp} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'PSPDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Previous-Employer Information' width={200}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByPreEmp} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'PEIDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Road Test Form and Certificate' width={200}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByRoadTest} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'RTFCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Entry-level Driver Training' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByEntryLevel} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'ELDTCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn
                binding='docIds'
                header='Longer-Combination Vehicle Training'
                width={220}
              >
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'LCVTCDocId')}
                />

                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByLonger} /> */}
              </FlexGridColumn>
              <FlexGridColumn
                binding='docIds'
                header='Miscellaneous'
                width={220}
              >
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'MISCDOCID')}
                />

                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByLonger} /> */}
              </FlexGridColumn>
            </FlexGrid>

            <FlexGrid
              ref={theGrid}
              id='theGridTallRows'
              alternatingRowStep={1}
              // autoRowHeights={true}
              className='driveClass cliniclisttable'
              itemsSource={view}
              initialized={gridInitialized}
              formatItem={formatItem}
              itemsPerPage={10}
              isReadOnly={true}
              scrollMode='Virtual' // Add this line for smooth scrolling
            >
              <div style={{ display: 'none' }}>
                <wjInput.ListBox
                  className='column-picker'
                  itemsSource={listboxColumns}
                  checkedMemberPath='visible'
                  displayMemberPath='header'
                  lostFocus={hidePicker}
                  initialized={initializedListBox}
                ></wjInput.ListBox>
              </div>
              <FlexGridColumn binding='employee[1]' header='Employee Name' width={160}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showEmpName} /> */}
              </FlexGridColumn>
              <FlexGridColumn binding='child_ids' header='Location' width={265}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showLocation} /> */}
              </FlexGridColumn>
              <FlexGridColumn binding='emailNew' header='Email' width={225}>
                <FlexGridCellTemplate cellType='Cell' template={showEmail} />
              </FlexGridColumn>
              <FlexGridColumn binding='newDL' header='DL' width={110}></FlexGridColumn>
              <FlexGridColumn binding='emp_status' header='Status' width={120}>
                <FlexGridCellTemplate cellType='Cell' template={showEmpStatus} />
              </FlexGridColumn>
              <FlexGridColumn binding='dlExp' header='DL Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showDlExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='mvrExp' header='MVR Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showMvrExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='mcExp' header='MC Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showMcExp} />
              </FlexGridColumn>
              <FlexGridColumn binding='covExp' header='COV Exp' width={135}>
                <FlexGridCellTemplate cellType='Cell' template={showCovExp} />
              </FlexGridColumn>
              {/* <FlexGridColumn
                binding='jobTitle'
                header='Job Title'
                // width='10*'
                // minWidth={180}
                // width='*'
                width={170}
              /> */}
              {/* <FlexGridColumn
                binding='departments'
                header='Job Position'
                // width='10*'
                minWidth={250}
                width='*'
              /> */}

              {/* <FlexGridColumn
                binding='program'
                header='Dot Mode'
                // width='10*'
                // minWidth={160}
                // width='*'
                width={100}
              >
                <FlexGridCellTemplate cellType='Cell' template={showDotMode} />
              </FlexGridColumn> */}
              {/* <FlexGridColumn
                binding='certDocs'
                header='Records'
                // width='12*'
                // minWidth={140}
                // width='*'
                width={90}
              >
                {/* <FlexGridCellTemplate cellType='Cell' template={showRecords} /> */}
              {/* </FlexGridColumn> */}

              <FlexGridColumn binding='docIds' header="Driver's Application" width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByDa} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'DADocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='DOT Clearinghouse' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByDot} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'DOTCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Initial Driving Record (MVR)' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByIdr} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'IDRMVRDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='CDLIs' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByCdlis} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'CDLISDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='PSP Search' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByPsp} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'PSPDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Previous-Employer Information' width={200}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByPreEmp} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'PEIDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Road Test Form and Certificate' width={200}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByRoadTest} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'RTFCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn binding='docIds' header='Entry-level Driver Training' width={180}>
                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByEntryLevel} /> */}
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'ELDTCDocId')}
                />
              </FlexGridColumn>
              <FlexGridColumn
                binding='docIds'
                header='Longer-Combination Vehicle Training'
                width={220}
              >
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'LCVTCDocId')}
                />

                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByLonger} /> */}
              </FlexGridColumn>
              <FlexGridColumn
                binding='docIds'
                header='Miscellaneous'
                width={220}
              >
                <FlexGridCellTemplate
                  cellType='Cell'
                  template={(item) => showDocByType(item, 'MISCDOCID')}
                />

                {/* <FlexGridCellTemplate cellType='Cell' template={showDocByLonger} /> */}
              </FlexGridColumn>
              <FlexGridFilter
                filterColumns={[
                  'employee[1]',
                  'child_ids',
                  'emailNew',
                  'certDocs',
                  'emp_status',
                  'mcExp',
                  'mvExp',
                  'mvrExp',
                  'dlExp',
                  'covExp',
                  // 'newEmpEIN',
                  // 'newEmpSSN',
                  'newDL'
                  // 'jobTitle',
                  // 'departments',
                  // 'program'
                ]}
              />
            </FlexGrid>

            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div
                style={{
                  backgroundColor: '#425a81',
                  color: '#fff',
                  display: 'inline',
                  borderRadius: '5px',
                  height: '30px'
                }}
              >
                <CollectionViewNavigator
                  style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                  headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                  byPage={true}
                  cv={view}
                />
              </div>
              &nbsp;&nbsp;&nbsp;
              <p
                style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}
                ref={filterRefCounter}
                id='filterCounter'
              >
                {Globalize.format(filterRefCounter.current, 'n0')}
              </p>
              &nbsp;
              <p style={{ fontWeight: 'bold', verticalAlign: 'baseline', fontSize: '14px' }}>
                {' '}
                out of {driverResponse.length}
              </p>
            </div>
            <CertificateModal
              isVisible={isCertModalVisible}
              certificates={selectedCertificates}
              certType={selectedCertType}
              onClose={() => setIsCertModalVisible(false)}
              downloadCert={downloadCert} // Pass the downloadCert function
            />
          </div>
        ) : (
          <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, margin: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        {/* <i className='icofont-exclamation-tringle' style={{ color: 'red', fontSize: 20 }}></i> */}
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default DriverQualification;
