import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Spin,
  Space,
  Row,
  Col,
  Input,
  Button,
  Collapse,
  Modal,
  notification,
  Tooltip,
  DatePicker,
  Card,
  Select
} from 'antd';
import setting from '../../config/setting';
import { history } from '../../redux/store';
import moment from 'moment';
import { InfoCircleOutlined, InfoCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import * as wijmo from '@grapecity/wijmo';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcPdf from '@grapecity/wijmo.pdf';
import '@grapecity/wijmo.styles/wijmo.css';
import { CollectionView, Globalize } from '@grapecity/wijmo';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import Search from 'antd/lib/input/Search';
import { EditTwoTone, InfoCircleFilled, FormOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

let flexes = '';
let showGrid = '';
let listBoxes = '';
let i = 1;

class ExportService {
  startExcelExport(flex, successCallback, errorCallback, progressCallback) {
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeColumnHeaders: true,
        includeStyles: false
        // formatItem: this._formatExcelItem
      },
      'employee_list.xlsx',
      successCallback,
      errorCallback,
      progressCallback,
      true
    );
  }
  cancelExcelExport(doneCollback) {
    wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCollback);
  }
  exportToPdf(flex, options) {
    wjcGridPdf.FlexGridPdfConverter.export(flex, 'FlexGrid.pdf', {
      maxPages: 100,
      exportMode: wjcGridPdf.ExportMode.All,
      scaleMode: wjcGridPdf.ScaleMode.ActualSize,
      documentOptions: {
        pageSettings: {
          layout: wjcPdf.PdfPageOrientation.Landscape
        },
        header: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        },
        footer: {
          declarative: {
            text: '\t&[Page]\\&[Pages]'
          }
        }
      },
      styles: {
        cellStyle: {
          backgroundColor: '#ffffff',
          borderColor: '#c6c6c6'
        },
        altCellStyle: {
          backgroundColor: '#f9f9f9'
        },
        groupCellStyle: {
          backgroundColor: '#dddddd'
        },
        headerCellStyle: {
          backgroundColor: '#eaeaea'
        },
        // Highlight Invalid Cells
        errorCellStyle: {
          backgroundColor: 'rgba(255, 0, 0, 0.3)'
        }
      },
      customCellContent: false
      // formatItem: (e) => this._formatPdfItem(e, options)
    });
  }

  _resetExcelContext(ctx) {
    ctx.exporting = false;
    ctx.progress = 0;
    ctx.preparing = false;
  }
}

const EmployeeView = () => {
  const companyId = parseInt(localStorage.getItem('customerId'));
  const pageLimit = 500;
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdvanceFilterModalOpen, setIsAdvanceFilterModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [noRecordMessage, setNoRecordMessage] = useState('No Record Found !');
  //const [searchText, setSearchText] = useState('');
  const [loadMore, setLoadMore] = useState(1);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [newPassportSearch, setNewPassportSearch] = useState('');
  const [newNationalitySearch, setNewNationalitySearch] = useState('');
  const [searchTerminationDate, setSearchTerminationDate] = useState('');
  const [searchHireRehireDate, setSearchHireRehireDate] = useState('');
  const [searchNewSupervisor, setSearchNewSupervisor] = useState('');
  const [searchStatusMarital, setSearchStatusMarital] = useState('');
  const [searchJobTitle, setSearchJobTitle] = useState('');
  const [searchAddCompany, setSearchAddCompany] = useState('');
  const [searchNewDept, setSearchNewDept] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [genderSearch, setGenderSearch] = useState('');
  const [programModeSearch, setProgramModeSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [searchDob, setSearchDob] = useState('');
  // const [searchRange,setSearchRange] = useState('');
  const [lastName, setLastName] = useState('');
  const [ssn, setSSN] = useState('');
  const [ein, setEIN] = useState('');
  const [dl, setDL] = useState('');
  const [fullName, setFullName] = useState('');
  const [tableType, setTableType] = useState('all');
  const [pageSize, setPageSize] = useState(10);
  const [dotMode, setDotMode] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalEmpRecords, setTotalEmpRecords] = useState(0);
  //const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [terminateRecords, setTerminateRecords] = useState('');
  const [activeCount, setActiveCount] = useState(0);
  const [deceaseCount, setDeceaseCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [suspendCount, setSuspendCount] = useState(0);
  const [preEmployment, setPreEmployment] = useState(0);
  const [terminateCount, setTerminateCount] = useState(0);
  const [typecheckValue, setTypeCheckValue] = useState('');
  //wijmo table

  const [view, setView] = useState();
  const [viewData, setViewData] = useState();
  const [isExcelExporting, setIsExcelExporting] = useState();
  const [isExcelPreparing, setIsExcelPreparing] = useState();
  const [excelProgress, setExcelProgress] = useState();
  const [listboxColumns, setListboxColumns] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [newName, setNewName] = useState('');

  const theGrid = useRef();
  const theSearch = useRef();
  const fixedContainerRef = useRef(null);
  const filterRefCounter = useRef();

  const getEmployeeCount = (forceReset = false) => {
    //employeeCustomerPortal2Count
    const customerData = JSON.parse(localStorage.getItem('customerData'));
    axios
      .post(setting.serverUrl + '/api/employeeCustCountCustomerPortal', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          fullName: forceReset ? '' : fullName || newName,
          // firstName: forceReset ? '' : firstName,
          // lastName: forceReset ? '' : lastName,
          ssn: forceReset ? '' : ssn,
          locationSearch: forceReset ? '' : locationSearch,
          emailSearch: forceReset ? '' : emailSearch,
          genderSearch: forceReset ? '' : genderSearch,
          program: forceReset ? '' : programModeSearch,
          statusSearch: forceReset ? '' : statusSearch,
          searchDob: forceReset ? '' : searchDob,
          ein: forceReset ? '' : ein,
          searchAddCompany: forceReset ? '' : searchAddCompany,
          searchHireRehireDate: forceReset ? '' : searchHireRehireDate,
          searchJobTitle: forceReset ? '' : searchJobTitle,
          searchNewDept: forceReset ? '' : searchNewDept,
          searchNewSupervisor: forceReset ? '' : searchNewSupervisor,
          searchStatusMarital: forceReset ? '' : searchStatusMarital,
          searchTerminationDate: forceReset ? '' : searchTerminationDate,
          newNationalitySearch: forceReset ? '' : newNationalitySearch,
          newPassportSearch: forceReset ? '' : newPassportSearch,
          //  limit: pageLimit,
          //companyId: companyId,
          custIdArr: customerData.employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setTotalRecords(response.data.value);

          //setTotalRecords(customerData.employee_multi_select.length);
        }
      });
  };
  const getEmployeeStatusCounts = () => {
    const customerData = JSON.parse(localStorage.getItem('customerData'));
    axios
      .post(setting.serverUrl + '/api/employeeStatusCount', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          companyId: JSON.parse(localStorage.getItem('customerData')).id,
          custIdArr: customerData.employee_multi_select
        }
      })
      .then((response) => {
        if (response.status === 200) {
          let statusCounts = response && response.data && response.data.rows;
          const filteredActive =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'active');
          const filteredPreEmp =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'pre employment');
          const filteredAbsence =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'leave of absence');
          const filteredDeceased =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'deceased');
          const filteredTerminated =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'terminated');
          const filteredSuspended =
            statusCounts &&
            statusCounts.length > 0 &&
            statusCounts.filter((e) => e.emp_status == 'Suspended');
          setTotalEmpRecords(customerData && customerData.employee_multi_select.length);
          setActiveCount(filteredActive && filteredActive[0] && filteredActive[0].count);
          setDeceaseCount(filteredDeceased && filteredDeceased[0] && filteredDeceased[0].count);
          setAbsenceCount(filteredAbsence && filteredAbsence[0] && filteredAbsence[0].count);
          setPreEmployment(filteredPreEmp && filteredPreEmp[0] && filteredPreEmp[0].count);
          setTerminateCount(
            filteredTerminated && filteredTerminated[0] && filteredTerminated[0].count
          );
          setSuspendCount(filteredSuspended && filteredSuspended[0] && filteredSuspended[0].count);
        } else if (response && response.data == 202) {
          setTotalEmpRecords(0);
          setActiveCount(0);
          setDeceaseCount(0);
          setAbsenceCount(0);
          setPreEmployment(0);
          setTerminateCount(0);
          setSuspendCount(0);
        }
      });
  };

  const getEmployee = (currentPageNumber = 1, forceReset = false, advanceFilter = false) => {
    setIsLoading(true);
    if (!forceReset && !advanceFilter) {
      setIsLoadMoreLoading(true);
    }
    i = 1;
    const customerData = JSON.parse(localStorage.getItem('customerData'));

    axios
      .post(setting.serverUrl + '/api/employeeCustomerPortal2', {
        params: {
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          loadMore: currentPageNumber,
          fullName: forceReset ? '' : fullName || newName,
          // firstName: forceReset ? '' : firstName,
          // lastName: forceReset ? '' : lastName,
          ssn: forceReset ? '' : ssn,
          locationSearch: forceReset ? '' : locationSearch,
          emailSearch: forceReset ? '' : emailSearch,
          genderSearch: forceReset ? '' : genderSearch,
          programModeSearch: forceReset ? '' : programModeSearch,
          statusSearch: forceReset ? '' : statusSearch,
          searchDob: forceReset ? '' : searchDob,
          // eventDateRange : searchRange,
          ein: forceReset ? '' : ein,
          dl: forceReset ? '' : dl,
          dotMode: forceReset ? '' : dotMode,
          searchAddCompany: forceReset ? '' : searchAddCompany,
          searchHireRehireDate: forceReset ? '' : searchHireRehireDate,
          searchJobTitle: forceReset ? '' : searchJobTitle,
          searchNewDept: forceReset ? '' : searchNewDept,
          searchNewSupervisor: forceReset ? '' : searchNewSupervisor,
          searchStatusMarital: forceReset ? '' : searchStatusMarital,
          searchTerminationDate: forceReset ? '' : searchTerminationDate,
          newNationalitySearch: forceReset ? '' : newNationalitySearch,
          newPassportSearch: forceReset ? '' : newPassportSearch,
          //companyId: companyId,
          custIdArr: customerData.employee_multi_select,
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          limit: pageLimit
        }
      })
      .then((response) => {
        setIsLoading(false);
        console.log(response, 'responseEmployeeList');
        if (response.status === 200) {
          // response.data.sort((a, b) => {
          //   const dateA = new Date(a.dob);
          //   const dateB = new Date(b.dob);
          //   return dateB - dateA;
          // });
          response.data.sort((a, b) => {
            const dateA = new Date(a.Hire_Rehire_Date);
            const dateB = new Date(b.Hire_Rehire_Date);
            return dateB - dateA;
          });
          var ress =
            response &&
            response.data &&
            response.data.length > 0 &&
            response.data.map((val) => {
              return {
                ...val,
                fullName:
                  val.first_name && val.last_name
                    ? val.first_name +
                      ' ' +
                      // (val.middle_name ? val.middle_name + ' ' : '')
                      val.last_name
                    : '',
                empLocId:
                  val.emp_location && val.emp_location.length > 0 ? val.emp_location[0] : '',

                emp_location:
                  val.emp_location &&
                  val.emp_location.length > 0 &&
                  !val.emp_location[1].includes('|') &&
                  !val.emp_location[1].includes(',')
                    ? val.emp_location[1]
                    : val.emp_location[1]?.split('|')[1] || val.emp_location[1]?.split(',')[1],

                newDOB: val.dob ? val.dob : '',
                newDOBforExcel: val.dob ? moment(val.dob).format('MM/DD/YYYY'):'',
                terminationDate:
                  val.Termination_Date != false
                    ? moment(val.Termination_Date).format('MM/DD/YYYY')
                    : '',
                hireRehireDate: val.Hire_Rehire_Date != false ? val.Hire_Rehire_Date : '',
                hireRehireDateforExcel: val.Hire_Rehire_Date != false ? moment(val.Hire_Rehire_Date).format('MM/DD/YYYY') : '',
                newPassport: val.passport != false ? val.passport : '',
                statusMarital: val.status_p != false ? val.status_p : '',
                newNationality: val.nationality != false ? val.nationality : '',
                newSupervisor: val.supervisor != false ? val.supervisor : '',
                jobTitle: val.Job_Title != false ? val.Job_Title : '',
                addCompany: val.add_company[1] != false ? val.add_company[1] : '',
                newDept: val.department != false ? val.department : '',
                newGender: val.Gender !== false ? val.Gender : '',
                newEmail: val.email !== false ? val.email : '',
                newEmpStatus: val.emp_status !== false ? val.emp_status : '',
                newProgram: val.program !== false ? val.program : '',
                newEin: val.emp_ein != false ? val.emp_ein : '',
                newSSN: val.emp_ssn != false ? '***-**-' + val.emp_ssn?.substr(-4) : ''
              };
            });
          console.log('wimo ress', ress);
          //setWijmoFlexGrid(ress, 10);
          if (ress.length > 0) {
            const collectionView1 = new CollectionView(ress, {
              pageSize: ress.length
            });
            setViewData(collectionView1);
            const collectionView2 = new CollectionView(ress, {
              pageSize: pageSize,
              collectionChanged: (s) => {
                filterRefCounter.current = s.totalItemCount;
                var filterCounter = document.getElementById('filterCounter');
                if (filterCounter) {
                  filterCounter.textContent = s.totalItemCount;
                }
              }
            });
            if (tableType === 'all') {
              collectionView2.filters.push((item) => {
                return item;
              });
            } else if (tableType === 'preEmployment') {
              collectionView2.filters.push((item) => {
                return item.event_status === 'Scheduled';
              });
            } else if (tableType === 'decease') {
              collectionView2.filters.push((item) => {
                return item.event_status === 'Completed';
              });
            } else if (tableType === 'absence') {
              collectionView2.filters.push((item) => {
                return item.event_status === 'Completed';
              });
            } else if (tableType === 'terminate') {
              collectionView2.filters.push((item) => {
                return item.event_status === 'Completed';
              });
            }

            setView(collectionView2);
          }

          setIsLoadMoreLoading(false);
          setEmployeeData(response.data);
          setLoadMore(currentPageNumber);
          setLoadMoreLoader(false);

          // setTotalRecords(response.data.length);
          setHasPermission(true);
          setErrMsg('Module not activated.');
          setTableData(ress);
          setNoRecordFound(false);
        } else if (response.status === 201) {
          setNoRecordFound(true);
          setNoRecordMessage('Access permission not allowed.');
          setErrMsg('Access permission not allowed.');
          // notification.error({
          // message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          // });
        } else if (response.status === 202) {
          setNoRecordFound(false);
          //  setWijmoFlexGrid([], 10);
          setHasPermission(true);
          setNoRecordMessage('No Record found');
        } else {
          notification.error({
            message: 'Failed to retrieve data at the moment. Please try again in a bit.'
          });
          setNoRecordFound(true);
        }
      });
  };

  const showAdvanceFilterModal = () => {
    setIsAdvanceFilterModalOpen(true);
  };

  const handleAdvanceFilterCancel = () => {
    setIsAdvanceFilterModalOpen(false);
  };

  const handleAdvanceFilter = () => {
    if (
      (firstName && firstName.length > 0) ||
      (lastName && lastName.length > 0) ||
      (fullName && fullName.length > 0) ||
      (ssn && ssn.length > 0) ||
      (locationSearch && locationSearch.length > 0) ||
      (genderSearch && genderSearch.length > 0) ||
      (emailSearch && emailSearch.length > 0) ||
      (programModeSearch && programModeSearch.length > 0) ||
      (statusSearch && statusSearch.length > 0) ||
      (searchDob && searchDob.length > 0) ||
      (ein && ein.length > 0) ||
      (dl && dl.length > 0) ||
      (dotMode && dotMode.length > 0) ||
      (newPassportSearch && newPassportSearch.length > 0) ||
      (newNationalitySearch && newNationalitySearch.length > 0) ||
      (searchTerminationDate && searchTerminationDate.length > 0) ||
      (searchHireRehireDate && searchHireRehireDate.length > 0) ||
      (searchNewSupervisor && searchNewSupervisor.length > 0) ||
      (searchStatusMarital && searchStatusMarital.length > 0) ||
      (searchJobTitle && searchJobTitle.length > 0) ||
      (searchAddCompany && searchAddCompany.length > 0) ||
      (searchNewDept && searchNewDept.length > 0) ||
      (terminateRecords && terminateRecords.length > 0)
    ) {
      getEmployee(1, false, true);
      setIsAdvanceFilterModalOpen(false);
      getEmployeeCount();
      setLoadMoreLoader(true);
    } else {
      setTableData(employeeData);
      setIsAdvanceFilterModalOpen(false);
      // setLoadMoreLoader(false)
    }
  };
  function openForm(data) {
    history.push({ pathname: `/vertical/editaddemployee`, state: { data } });
  }
  function addEmployee() {
    console.log('addEmployee');

    //  history.push({ pathname: `/vertical/addEmployee`, state: {} });
    history.push({ pathname: `/vertical/newEmployeeInstruction`, state: {} });
  }

  const handleShowInfo = (patient) => {
    console.log('patient', patient);
    history.push({
      pathname: '/vertical/employeeDetails',
      state: patient
    });
  };

  useEffect(() => {
    getEmployee();
    getEmployeeCount();
    getEmployeeStatusCounts();
    var typeValue = localStorage.getItem('IdtypeCheckValue');
    setTypeCheckValue(JSON.parse(typeValue));
  }, [pageSize, tableType]);

  const handleChange = (e) => {
    setNewName(e.target.value);
    // setFullName(newName);
  };

  const onSearch = (value) => {
    if (newName.length > 0) {
      getEmployee(1, false, true);
      getEmployeeCount();
      // setFullName('');
    }
  };

  const handleReset = () => {
    setIsLoading(true);
    setLoadMoreLoader(true);
    setNewName('');
    setNewPassportSearch('');
    setNewNationalitySearch('');
    setSearchTerminationDate('');
    setSearchHireRehireDate('');
    setSearchNewSupervisor('');
    setSearchStatusMarital('');
    setSearchJobTitle('');
    setSearchAddCompany('');
    setSearchNewDept('');
    setFirstName('');
    setFullName('');
    setLastName('');
    setSSN('');
    setEIN('');
    setDL('');
    setDotMode('');
    setSearchDob('');
    setStatusSearch('');
    setLocationSearch('');
    setEmailSearch('');
    setGenderSearch('');
    setSearchDob('');
    setTerminateRecords('');
    getEmployee(1, true);
    getEmployeeCount(true);
  };

  const shouldShowLoadMore = () => {
    return loadMore * pageLimit <= totalRecords;
  };

  const advancedDobFilter = (filterType, value) => {
    if (filterType === 'dob') {
      console.log('DOB: ', value);
      setSearchDob(value);
    }
  };
  const cardTerminateFilter = (filterType, value) => {
    if (filterType === 'terminate') {
      setTerminateRecords(value);
    }
  };

  const advancedHireRehireFilter = (filterType, value) => {
    if (filterType === 'Hire_Rehire_Date') {
      console.log('Hire_Rehire_Date ', value);
      setSearchHireRehireDate(value);
    }
  };
  const advancedTerminationFilter = (filterType, value) => {
    if (filterType === 'Termination_Date') {
      console.log('Termination_Date: ', value);
      setSearchTerminationDate(value);
    }
  };

  // let theGrids = theGrid.current;
  // let theSearchs = theSearch.current;
  // if (theGrids && theSearchs) {
  //   theSearchs.control.grid = theGrids.control;
  // }

  /* Wijmo pagesize set functionality */
  const paggination = (value) => {
    setItemPerPage(value);
    setPageSize(value);
    view.refresh();
  };
  const filterTable = (type) => {
    setTableType(type);
  };

  const exportToExcel = () => {
    const exportService = new ExportService();
    const resetState = () => {
      setIsExcelPreparing(false);
      setIsExcelExporting(false);
      setExcelProgress(0);
    };
    if (!isExcelPreparing && !isExcelExporting) {
      setIsExcelPreparing(true);
      exportService.startExcelExport(
        flexes,
        () => {
          console.log('Export to Excel completed');
          resetState();
        },
        (err) => {
          console.error(`Export to Excel failed: ${err}`);
          resetState();
        },
        (prg) => {
          setIsExcelPreparing(false);
          setIsExcelExporting(true);
          setExcelProgress(prg);
        }
      );
      console.log('Export to Excel started');
    } else {
      exportService.cancelExcelExport((progress) => {
        console.log('Export to Excel canceled');
        resetState();
      });
    }
  };

  const initializedListBox = (listbox) => {
    listBoxes = listbox;
  };

  const showPicker = (e) => {
    // show the column picker when the user clicks the top-left cell
    const reference = showGrid.hostElement.querySelector('.wj-topleft');
    wijmo.showPopup(listBoxes.hostElement, reference, false, true, false);
    listBoxes.focus();
    e.preventDefault();
  };
  const hidePicker = () => {
    wijmo.hidePopup(listBoxes.hostElement);
  };

  const formatItem = (sender, e) => {
    if (e.panel == sender.topLeftCells) {
      var span = document.createElement('span');
      span.className = 'column-picker-icon glyphicon glyphicon-cog';
      span.innerHTML = '&#9733';
      span.style.color = '#425a81';
      span.addEventListener('mousedown', function (e) {
        console.log('calliing show picker');
        showPicker(e);
      });
      e.cell.appendChild(span);
    }
    //for custom height of cells
    const flex = sender,
      col = flex.columns[e.col],
      row = flex.rows[e.row];
    if (row) {
      row.height = 36; // You can adjust the height as needed
    }
  };

  const gridInitialized = (ctl) => {
    ctl.select(-1, -1);
    showGrid = ctl;
    setListboxColumns(ctl.columns);
  };

  const gridInitializedData = (ctl) => {
    flexes = ctl;
  };

  const showFullName = (item) => {
    return (
      <p>
        {item.item.first_name && item.item.last_name
          ? item.item.first_name +
            ' ' +
            // (item.item.middle_name != "" ? item.item.middle_name + ' ' : '') +
            item.item.last_name
          : ''}
      </p>
    );
  };

  const showLocation = (item) => {
    return <p>{item.item.emp_location ? item.item.emp_location : ''}</p>;
  };
  const showJobTitle = (item) => {
    return <p>{item.item.jobTitle ? item.item.jobTitle : ''}</p>;
  };
  const showAddCompany = (item) => {
    return <p>{item.item.addCompany ? item.item.addCompany : ''}</p>;
  };

  const showDOB = (item) => {
    return <p>{item.item.dob ? moment(item.item.dob).format('MM/DD/YYYY') : ''}</p>;
  };

  const showSSN = (item) => {
    return <span>{item.item.newSSN}</span>;
  };

  const showEIN = (item) => {
    return <span>{item.item.newEin}</span>;
  };

  const showDL = (item) => {
    return <span>{item.item.DL}</span>;
  };

  const showGender = (item) => {
    return <p>{item.item.Gender}</p>;
  };

  const showEmail = (item) => {
    return <span>{item.item.email !== false && item.item.email}</span>;
  };

  const showHireDate = (item) => {
    return (
      <span>
        {item.item.hireRehireDate ? moment(item.item.hireRehireDate).format('MM/DD/YYYY') : ''}
      </span>
    );
  };

  const showEmpSupervisor = (item) => {
    return <span>{item.item.newSupervisor !== null && item.item.newSupervisor}</span>;
  };
  const showEmpStatus = (item) => {
    return (
      <p>
        {item.item.emp_status === 'active' ? (
          <strong>Active</strong>
        ) : item.item.emp_status === 'terminated' ? (
          <strong>Terminated</strong>
        ) : item.item.emp_status === 'deceased' ? (
          <strong>Deceased</strong>
        ) : item.item.emp_status === 'pre employment' ? (
          <strong>Pre-Employment</strong>
        ) : item.item.emp_status === 'leave of absence' ? (
          <strong>Leave of absence</strong>
        ) : item.item.emp_status === 'leave of absence' ? (
          <strong>Leave of absence</strong>
        ) : (
          <strong>{item.item.emp_status}</strong>
        )}
      </p>
    );
  };

  const showDotmode = (item) => {
    return <p> {item.item.program !== 'NON - DOT' ? item.item.mode : 'NON-DOT'}</p>;
  };

  // const showAction = (item) => {
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-7px' }}>
  //       <Button
  //         onClick={() => openForm(item.item)}
  //         type='primary'
  //         style={{
  //           background: 'none',
  //           border: 'none',
  //           boxShadow: 'none',
  //           color: 'rgb(27,57,105)',
  //           margin: '-2px' // Adjust this value as needed
  //         }}
  //       >
  //         <span className='icofont-edit' style={{ fontSize: 13 }} />
  //       </Button>
  //       <Button
  //         shape='circle'
  //         onClick={() => handleShowInfo(item.item)}
  //         danger
  //         style={{
  //           background: 'none',
  //           border: 'none',
  //           boxShadow: 'none',
  //           color: 'rgb(239,103,39)',
  //           margin: '-2px' // Adjust this value as needed
  //         }}
  //       >
  //         <span className='icofont icofont-info-circle' style={{ fontSize: 15 }} />
  //       </Button>
  //     </div>
  //   );
  // };

  const showAction = (item) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px', marginLeft: -8 }}>
        {/* <EditTwoTone
          onClick={() => openForm(item.item)}
          style={{ fontSize: 16 }}
          twoToneColor={'#888888'}
        /> */}
        <FormOutlined
          onClick={() => openForm(item.item)}
          style={{ fontSize: 16 }}
          twoToneColor={'#888888'}
        />

        <InfoCircleTwoTone
          onClick={() => handleShowInfo(item.item)}
          style={{ marginLeft: 15, color: '#0066ff', fontSize: 15 }}
          twoToneColor='#0066ff'
        />
      </div>
    );
  };
  return hasPermission ? (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <div style={{ display: 'flex' }}>
          <h3 style={{ padding: 10, textAlign: 'left', color: '#1C3A6A' }}>Employee List</h3>
          <Tooltip placement='top' title='Add Employee Instruction'>
            <PlusCircleTwoTone
              style={{ fontSize: 25, marginTop: '40px', cursor: 'pointer' }}
              onClick={addEmployee}
            />
          </Tooltip>
        </div>
        {totalEmpRecords && totalEmpRecords > 0 ? (
          <div>
            {/* <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}> */}

            {shouldShowLoadMore() && (
              <Button
                style={{
                  backgroundColor: '#247',
                  color: 'white',
                  border: 'none',
                  marginRight: '10px'
                }}
                size='small'
                onClick={() => {
                  getEmployee(loadMore + 1);
                  setLoadMoreLoader(true);
                }}
              >
                {isLoadMoreLoading ? (
                  <Space size='middle'>
                    <Spin size='small' />
                  </Space>
                ) : (
                  ' Load More'
                )}
              </Button>
            )}
            {/* </div> */}

            <Button
              size='small'
              style={{
                marginRight: '11px',
                backgroundColor: '#247',
                border: 'none'
              }}
              onClick={showAdvanceFilterModal}
            >
              Advanced Search Filter
            </Button>
            <Modal
              width={1000}
              title='Advanced Search Filter'
              visible={isAdvanceFilterModalOpen}
              onCancel={handleAdvanceFilterCancel}
              footer={[
                <Button
                  size='small'
                  style={{ backgroundColor: 'rgb(61 88 106)', color: 'white' }}
                  onClick={() => handleAdvanceFilter()}
                >
                  Search
                </Button>
              ]}
            >
              <Row gutter={[24, 24]}>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={fullName}
                    placeholder='Name :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={locationSearch}
                    placeholder='Location :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setLocationSearch(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={emailSearch}
                    placeholder='Email :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setEmailSearch(event.target.value)}
                  />
                </Col>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    if (value === 'SSN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                          <Input
                            value={ssn}
                            placeholder='SSN:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '100%', backgroundColor: 'white' }}
                            onChange={(event) => setSSN(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'Driver License') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={dl}
                            placeholder='Driver License:'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '100%', backgroundColor: 'white' }}
                            onChange={(event) => setDL(event.target.value)}
                          />
                        </Col>
                      );
                    } else if (value === 'EIN') {
                      return (
                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
                          <Input
                            value={ein}
                            placeholder='EIN'
                            suffix={<span className='icofont icofont-search' />}
                            style={{ width: '100%', backgroundColor: 'white' }}
                            onChange={(event) => setEIN(event.target.value)}
                          />
                        </Col>
                      );
                    } else {
                    }
                  })}

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={genderSearch}
                    placeholder='Gender :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setGenderSearch(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={statusSearch}
                    placeholder='Status :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setStatusSearch(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <DatePicker
                    placeholder='DOB :'
                    style={{
                      width: '100%',
                      backgroundColor: 'white',
                      height: '40px',
                      borderRadius: '20px'
                    }}
                    onChange={(date, dateString) => advancedDobFilter('dob', dateString)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={dotMode}
                    placeholder='DOT MODE :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setDotMode(event.target.value)}
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={newPassportSearch}
                    placeholder='Passport :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setNewPassportSearch(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={searchNewSupervisor}
                    placeholder='Supervisor :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setSearchNewSupervisor(event.target.value)}
                  />
                </Col>

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={searchJobTitle}
                    placeholder='Job title :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setSearchJobTitle(event.target.value)}
                  />
                </Col>
                {/* <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <Input
                    value={searchNewDept}
                    placeholder='Department :'
                    suffix={<span className='icofont icofont-search' />}
                    style={{ width: '100%', backgroundColor: 'white' }}
                    onChange={(event) => setSearchNewDept(event.target.value.toUpperCase())}
                  />
                </Col> */}

                <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
                  <RangePicker
                    //value={searchDateRange}
                    placeholder={['Start Hire/Rehire', 'End Hire/Rehire']}
                    allowClear={true}
                    style={{ borderRadius: 20, width: '100%', height: 40 }}
                    onChange={(value, dateString) => {
                      advancedHireRehireFilter('Hire_Rehire_Date', dateString);
                    }}
                  />
                </Col>
              </Row>
            </Modal>
            <Button
              size='small'
              style={{ marginTop: '10px', backgroundColor: '#247', border: 'none' }}
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>

      <br />

      <div className='row'>
        {totalEmpRecords != 0 && totalEmpRecords != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('all')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {totalEmpRecords}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Total <br /> Employees
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {preEmployment != 0 && preEmployment != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('preEmployment')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {preEmployment}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with <br /> Pre Employment Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {activeCount != 0 && activeCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('active')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {activeCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with Active Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {absenceCount != 0 && absenceCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('absence')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {absenceCount > 0 && absenceCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with <br /> Leave Of Absence Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {suspendCount !== 0 && suspendCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('suspend')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {suspendCount > 0 && suspendCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with <br /> Suspended Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {deceaseCount !== 0 && deceaseCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('decease')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {deceaseCount > 0 && deceaseCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with Deceased Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
        {terminateCount !== 0 && terminateCount != undefined && (
          <div className='col-12 col-md-6 col-xl-2'>
            <Card
              style={{ background: 'rgba(251, 251, 251)', height: 150 }}
              className='animated with-shadow'
              //onClick={() => filterTable('terminate')}
            >
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <div
                    className='count'
                    style={{
                      fontSize: 40,
                      color: '#EF6827',
                      lineHeight: 1.4,
                      fontWeight: 'bold'
                    }}
                  >
                    {terminateCount}
                  </div>
                  <h6 className='mt-0 mb-1' style={{ color: '#707070' }}>
                    Employees with Terminated Status
                  </h6>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>

      <br />

      {(isLoadMoreLoading || isLoading) && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}

      {!noRecordFound ? (
        tableData.length > 0 ? (
          <>
            <Row>
              <Col style={{ fontSize: 15, fontWeight: 700 }}>
                {totalRecords ? 'Total Records : ' + totalRecords : ''}
              </Col>
            </Row>
            <br />
            <div>
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                  {/* <FlexGridSearch
                    ref={theSearch}
                    placeholder='Search'
                    cssMatch=''
                    style={{ width: '70%', height: '40px' }}
                  /> */}
                  <Search
                    placeholder='Search By Employee Name'
                    enterButton
                    // loading={isLoading}
                    value={newName}
                    onSearch={onSearch}
                    onChange={handleChange}
                    size='small'
                    // style={{ width: 304 }}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={4} style={{ textAlign: 'center' }}>
                  <div ref={fixedContainerRef}>
                    <Select
                      style={{ width: 170 }}
                      placeholder={`Items per page : ${pageSize}`}
                      onChange={paggination}
                      options={[
                        { label: 'Items per page : 10', value: 10 },
                        { label: 'Items per page : 20', value: 20 },
                        { label: 'Items per page : 50', value: 50 }
                      ]}
                      getPopupContainer={() => fixedContainerRef.current}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={8} md={8} lg={8} xl={10}>
                  <Button
                    size='small'
                    style={{ backgroundColor: '#3d586a', border: 'none', marginLeft: '302px' }}
                    onClick={() => handleReset()}
                  >
                    Reset Filter
                  </Button>
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#3d586a', float: 'right' }}
                    disabled={isExcelPreparing}
                    onClick={exportToExcel}
                    size='small'
                  >
                    {isExcelExporting ? `Cancel (${excelProgress}% done)` : 'Export To Excel'}
                  </Button>
                </Col>
              </Row>

              {/* to export entire data of table */}
              <FlexGrid
                ref={theGrid}
                style={{ display: 'none' }}
                className='dummyWijmoData'
                itemsSource={viewData}
                initialized={gridInitializedData}
                formatItem={formatItem}
                isReadOnly={true}
              >
                <FlexGridColumn binding='Action' header='Action' width={80}>
                  <FlexGridCellTemplate cellType='Cell' template={showAction} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='fullName'
                  header='Employee Name'
                  width={150}
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showFullName} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newSSN'
                            : value === 'EIN'
                            ? 'newEin'
                            : value === 'Driver License'
                            ? 'DL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'
                        width={110}
                        wordWrap={true}
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}
                <FlexGridColumn binding='newDOBforExcel' header='DOB' width={100}>
                  {/* <FlexGridCellTemplate cellType='Cell' template={showDOB} /> */}
                </FlexGridColumn>

                <FlexGridColumn
                  binding='emp_location'
                  header='Location'
                  width={190}
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                </FlexGridColumn>

                {/* <FlexGridColumn
                  binding='newPassport'
                  header='Passport'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}

                <FlexGridColumn
                  binding='newGender'
                  header='Gender'
                  // width='10*'
                  width={80}
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showGender} />
                </FlexGridColumn>
                {/* <FlexGridColumn
                  binding='statusMarital'
                  header='Marital Status'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}
                {/* <FlexGridColumn
                  binding='newNationality'
                  header='Nationality'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}
                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  minWidth={200}
                  width='*'
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />
                </FlexGridColumn>
                {/* <FlexGridColumn
                  binding='newDept'
                  header='Jos Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn binding='newEmpStatus' header='Status' width={120} wordWrap={true}>
                  <FlexGridCellTemplate cellType='Cell' template={showEmpStatus} />
                </FlexGridColumn>

                <FlexGridColumn binding='newProgram' header='DOT Mode' width={100} wordWrap={true}>
                  <FlexGridCellTemplate cellType='Cell' template={showDotmode} />
                </FlexGridColumn>
                <FlexGridColumn binding='hireRehireDateforExcel' header='Hire Date' width={100}/>
                <FlexGridColumn binding='newEmail' header='Email' width={235}>
                  <FlexGridCellTemplate cellType='Cell' template={showEmail} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newSupervisor'
                  header='Supervisor'
                  // width='10*'
                  width={115}
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showEmpSupervisor} />
                </FlexGridColumn>
              </FlexGrid>

              <FlexGrid
                ref={theGrid}
                id='theGridTallRows'
                className='cliniclisttable'
                alternatingRowStep={0}
                // autoRowHeights={true}
                itemsSource={view}
                initialized={gridInitialized}
                formatItem={formatItem}
                itemsPerPage={10}
                isReadOnly={true}
                scrollMode='Virtual' // Add this line for smooth scrolling
              >
                <div style={{ display: 'none' }}>
                  <wjInput.ListBox
                    className='column-picker'
                    itemsSource={listboxColumns}
                    checkedMemberPath='visible'
                    displayMemberPath='header'
                    lostFocus={hidePicker}
                    initialized={initializedListBox}
                  ></wjInput.ListBox>
                </div>
                <FlexGridColumn binding='Action' header='Action' width={80}>
                  <FlexGridCellTemplate cellType='Cell' template={showAction} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='fullName'
                  header='Employee Name'
                  width={250}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showFullName} />
                </FlexGridColumn>

                {typecheckValue &&
                  typecheckValue.length > 0 &&
                  typecheckValue.map((value) => {
                    return (
                      <FlexGridColumn
                        binding={
                          value === 'SSN'
                            ? 'newSSN'
                            : value === 'EIN'
                            ? 'newEin'
                            : value === 'Driver License'
                            ? 'DL'
                            : ''
                        }
                        header={
                          value === 'SSN'
                            ? 'SSN'
                            : value === 'EIN'
                            ? 'EIN'
                            : value === 'Driver License'
                            ? 'Driver License'
                            : ''
                        }
                        // width='12*'
                        width={100}
                        wordWrap={true}
                      >
                        <FlexGridCellTemplate
                          cellType='Cell'
                          template={
                            value === 'SSN'
                              ? showSSN
                              : value === 'EIN'
                              ? showEIN
                              : value === 'Driver License'
                              ? showDL
                              : ''
                          }
                        />
                      </FlexGridColumn>
                    );
                  })}
                <FlexGridColumn
                  binding='newDOB'
                  header='DOB'
                  width={100}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDOB} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='emp_location'
                  header='Location'
                  width={260}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showLocation} />
                </FlexGridColumn>

                {/* <FlexGridColumn
                  binding='newPassport'
                  header='Passport'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}

                <FlexGridColumn
                  binding='newGender'
                  header='Gender'
                  // width='10*'
                  width={100}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showGender} />
                </FlexGridColumn>
                {/* <FlexGridColumn
                  binding='statusMarital'
                  header='Marital Status'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}
                {/* <FlexGridColumn
                  binding='newNationality'
                  header='Nationality'
                  // width='10*'
                  minWidth={150}
                  width='*'
                  wordWrap={true}
                /> */}
                <FlexGridColumn
                  binding='jobTitle'
                  header='Job Title'
                  // width='10*'
                  // minWidth={200}
                  // width='*'
                  width={350}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showJobTitle} />
                </FlexGridColumn>
                {/* <FlexGridColumn
                  binding='newDept'
                  header='Jos Position'
                  // width='10*'
                  minWidth={250}
                  width='*'
                /> */}

                <FlexGridColumn
                  binding='newEmpStatus'
                  header='Status'
                  width={120}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showEmpStatus} />
                </FlexGridColumn>

                <FlexGridColumn
                  binding='newProgram'
                  header='DOT Mode'
                  width={120}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showDotmode} />
                </FlexGridColumn>
                <FlexGridColumn binding='hireRehireDate' header='Hire Date' width={120}>
                  <FlexGridCellTemplate cellType='Cell' template={showHireDate} />
                </FlexGridColumn>
                <FlexGridColumn binding='newEmail' header='Email' width={225}>
                  <FlexGridCellTemplate cellType='Cell' template={showEmail} />
                </FlexGridColumn>
                <FlexGridColumn
                  binding='newSupervisor'
                  header='Supervisor'
                  // width='10*'
                  width={115}
                  // wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showEmpSupervisor} />
                </FlexGridColumn>

                {/* <FlexGridColumn
                  binding='addCompany'
                  header='Additional Company'
                  // width='10*'
                  minWidth={250}
                  width='*'
                  wordWrap={true}
                >
                  <FlexGridCellTemplate cellType='Cell' template={showAddCompany} />
                </FlexGridColumn> */}

                {/* <FlexGridColumn
                  binding='terminationDate'
                  header='Termination Date'
                  // width='10*'
                  minWidth={160}
                  width='*'
                  wordWrap={true}
                /> */}
                <FlexGridFilter
                  filterColumns={[
                    'name',
                    'fullName',
                    'emp_location',
                    'newDOB',
                    'newSSN',
                    'newEin',
                    'DL',
                    'newEmail',
                    'newGender',
                    'newEmpStatus',
                    'newProgram',
                    //'newPassport',
                    //'terminationDate',
                    'hireRehireDate',
                    'newSupervisor',
                    //'statusMarital',
                    //'addCompany',
                    'newDept',
                    //'newNationality',
                    'jobTitle'
                  ]}
                />
                {/* <FlexGridFilter/> */}
              </FlexGrid>

              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div
                  style={{
                    backgroundColor: '#425a81',
                    color: '#fff',
                    display: 'inline',
                    borderRadius: '5px',
                    height: '30px'
                  }}
                >
                  <CollectionViewNavigator
                    style={{ color: '#fff', backgroundColor: '#425a81', height: '30px' }}
                    headerFormat='Page {currentPage:n0} of {pageCount:n0}'
                    byPage={true}
                    cv={view}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <p
                  style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}
                  ref={filterRefCounter}
                  id='filterCounter'
                >
                  ({Globalize.format(filterRefCounter.current, 'n0')} )
                </p>
                &nbsp;
                <p style={{ fontWeight: 'bold', verticalAlign: 'baseline' }}>
                  {' '}
                  out of {employeeData.length}
                </p>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                  {shouldShowLoadMore() && (
                    <Button
                      style={{
                        backgroundColor: '#247',
                        color: 'white',
                        border: 'none'
                      }}
                      size='small'
                      onClick={() => {
                        getEmployee(loadMore + 1);
                        setLoadMoreLoader(true);
                      }}
                    >
                      {isLoadMoreLoading ? (
                        <Space size='middle'>
                          <Spin size='small' />
                        </Space>
                      ) : (
                        ' Load More'
                      )}
                    </Button>
                  )}
                </div>
              </div>

              {/* </ReactDragListView.DragColumn> */}
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <Row>
                <Col span={10}></Col>
                <Col span={8}>
                  <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
                    <Spin size='large' />
                  </Space>
                </Col>
              </Row>
            ) : (
              <h5 style={{ color: '#ef6727', textAlign: 'center' }}>{noRecordMessage}</h5>
            )}
          </>
        )
      ) : (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  ) : (
    <div>
      {isLoading && (
        <Row>
          <Col span={10}></Col>
          <Col span={8}>
            <Space size='middle' style={{ marginTop: 50, marginLeft: 85 }}>
              <Spin size='large' />
            </Space>
          </Col>
        </Row>
      )}
      <p style={{ fontSize: 18, fontWeight: 700, padding: 20, color: 'red' }}>
        &nbsp;&nbsp; {errMsg}
      </p>
    </div>
  );
};

export default EmployeeView;
