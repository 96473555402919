import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Checkbox, Button, notification, Spin, Space } from 'antd';
import axios from 'axios';
import setting from './../../../config/setting';
import { history } from '../../../redux/store';

const Profile = () => {
  const [user, setUser] = useState();
  const [dashboardPermission, setDashboardPermission] = useState([]);
  const [notificationcheck, setNotificationCheck] = useState(false);
  const [openeventcheck, setOpenEventCheck] = useState(false);
  const [randomcheck, setRandomCheck] = useState(false);
  const [schedulecheck, setScheduledCheck] = useState(false);
  const [progresscheck, setProgressCheck] = useState(false);
  const [emptestedcheck, setEmpTestedCheck] = useState(false);
  const [empvaccinatedcheck, setEmpVaccinatedCheck] = useState(false);
  const [empexemptedcheck, setEmpExemptedCheck] = useState(false);
  const [empvulnerablecheck, setEmpVulnerableCheck] = useState(false);
  const [empactivecheck, setEmpActiveCheck] = useState(false);
  const [loader, setLoader] = useState(false);

  const history = useHistory();
  const updateLocalStorage = (title, statusss) => {
    console.log('title', title, statusss);
    var b = '';
    const a = dashboardPermission.filter((val, index) => {
      console.log('val val al', val.title === title);
      if (val.title === title) {
        console.log('inside advanc', val);
        b = index;
        return val;
      }
    });
    console.log('value of a', a);
    a[0].status = statusss;
    // delete dashboardPermission[b]
    dashboardPermission.splice(b, 1);
    dashboardPermission.push(a[0]);
    // console.log("aaaaaaaaaa",a)
    console.log('dashboard pushed', dashboardPermission, b);
  };
  const handleSave = async () => {
    setLoader(true);
    console.log('userDashboardPermission params', dashboardPermission);
    await axios
      .post(setting.serverUrl + '/api/userDashboardPermissionUpdate', {
        params: {
          email: localStorage.getItem('myData'),
          user: localStorage.getItem('myData'),
          pass: localStorage.getItem('myPass'),
          compId: JSON.parse(localStorage.getItem('customerData')).id,
          dashboardPermission: dashboardPermission
        }
      })
      .then(async (response) => {
        console.log('response of dashboard permission in profile', response.data[0]);
        if (response.data && response.data[0]) {
          setLoader(false);
          notification.open({
            message: 'Dashboard settings saved successfully!',
            onClick: () => {
              console.log('Notification Clicked!');
            }
          });

          localStorage.setItem('dashboard_permision', JSON.stringify(response.data[0].permissions));
          localStorage.setItem('dashboard_status', JSON.stringify(true));
          history.push({ pathname: '/vertical/default-dashboard' });
        }
      });
  };
  const setopenevent = (e) => {
    console.log('backgroundScreeningMenu', e.target.checked);
    setOpenEventCheck(e.target.checked);
    updateLocalStorage('Open Events', e.target.checked);
  };

  const setrandompool = (e) => {
    console.log('covidTestingMenu', e.target.checked);
    // setRandomCheck(e.target.checked)
    // updateLocalStorage('Employee Vaccinated')
  };
  const setscheduleevent = (e) => {
    console.log('customersMenu', e.target.checked);
    setScheduledCheck(e.target.checked);
    updateLocalStorage('Scheduled Events', e.target.checked);
  };
  const settestinprogress = (e) => {
    console.log('employeeInformationMenu', e.target.checked);
    // setEmpTestedCheck(e.target.checked)
    setProgressCheck(e.target.checked);
    updateLocalStorage('Test in progress', e.target.checked);
  };
  const setnotification = (e) => {
    console.log('activeAllModule', e.target.checked);
    setNotificationCheck(e.target.checked);
    updateLocalStorage('Notifications', e.target.checked);
  };
  const setemployeetested = (e) => {
    console.log('medicalExaminersMenu', e.target.checked);
    setEmpTestedCheck(e.target.checked);
    updateLocalStorage('Employees Tested', e.target.checked);
  };
  const setemployeevaccinated = (e) => {
    console.log('notificationTypeMenu', e.target.checked);
    setEmpVaccinatedCheck(e.target.checked);
    updateLocalStorage('Employee Vaccinated', e.target.checked);
  };
  const setexemptedemployee = (e) => {
    console.log('physiciansMenu', e.target.checked);
    setEmpExemptedCheck(e.target.checked);
    updateLocalStorage('Exempted Employees', e.target.checked);
  };
  const setvulnerableemployee = (e) => {
    console.log('testDetailsMenu', e.target.checked);
    setEmpVulnerableCheck(e.target.checked);
    updateLocalStorage('Vulnerable Employees', e.target.checked);
  };
  const setactiveemployee = (e) => {
    console.log('typeOfEventMenu', e.target.checked);
    setEmpActiveCheck(e.target.checked);
    updateLocalStorage('Active Employees', e.target.checked);
  };

  useEffect(() => {
    const user = localStorage.getItem('myData');
    console.log('asdf', user);
    localStorage.setItem('dashboard_status', JSON.stringify(true));
    const dashboard_permision = localStorage.getItem('dashboard_permision');
    const dc = JSON.parse(dashboard_permision);
    setUser(user);
    setDashboardPermission(dc);
    console.log('get user info', dc);
    if (dc && dc.length > 0) {
      dc.map((val) => {
        console.log('val.notlength', val.notLength);
        if ((val.notLength === 0 || val.notLength > 0) && val.status) {
          setNotificationCheck(true);
        } else if ((val.event === 0 || val.event > 0) && val.status) {
          setScheduledCheck(true);
        } else if (
          (val.EmployeeCountVaccinated === 0 || val.EmployeeCountVaccinated > 0) &&
          val.status
        ) {
          setEmpVaccinatedCheck(true);
        } else if (
          (val.covidExemptionDataCounts === 0 || val.covidExemptionDataCounts > 0) &&
          val.status
        ) {
          setEmpExemptedCheck(true);
        } else if (
          (val.covidVulnerDataCounts === 0 || val.covidVulnerDataCounts > 0) &&
          val.status
        ) {
          setEmpVulnerableCheck(true);
        } else if ((val.openEvent === 0 || val.openEvent > 0) && val.status) {
          setOpenEventCheck(true);
        } else if ((val.randomPool === 0 || val.randomPool > 0) && val.status) {
          setRandomCheck(true);
        } else if (
          (val.CovidTestDataPendingVal === 0 || val.CovidTestDataPendingVal > 0) &&
          val.status
        ) {
          setProgressCheck(true);
        } else if ((val.employeeCount === 0 || val.employeeCount > 0) && val.status) {
          setEmpTestedCheck(true);
        } else if ((val.activeEmployees === 0 || val.activeEmployees > 0) && val.status) {
          setEmpActiveCheck(true);
        } else {
        }
      });
    }
  }, []);
  return (
    <>
      <div style={{ backgroundColor: 'white', padding: '20px' }}>
        {/* <h3>{user && user.name}</h3> */}
        <h5>{user}</h5>
      </div>

      <div style={{ backgroundColor: 'white', padding: '20px', marginTop: '20px' }}>
        <Row>
          <Col span={24}>
            <h3>Dashboard Settings</h3>
          </Col>
        </Row>

        <Row style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <div style={{ backgroundColor: 'white', padding: '30px', marginTop: '20px' }}>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Notifications</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={notificationcheck} onChange={setnotification}></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Open Events</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={openeventcheck} onChange={setopenevent}></Checkbox>
                </Col>
              </Row>
              {/* <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Random pool %</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={randomcheck} onChange={setrandompool}></Checkbox>
                </Col>
              </Row> */}
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Employees Tested</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={emptestedcheck} onChange={setemployeetested}></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Scheduled Events</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={schedulecheck} onChange={setscheduleevent}></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Test in progress</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={progresscheck} onChange={settestinprogress}></Checkbox>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={12}>
            <div style={{ backgroundColor: 'white', padding: '30px', margin: '20px 0px 0px 20px' }}>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Employee Vaccinated</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox
                    checked={empvaccinatedcheck}
                    onChange={setemployeevaccinated}
                  ></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Exempted Employees</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={empexemptedcheck} onChange={setexemptedemployee}></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Vulnerable Employees</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox
                    checked={empvulnerablecheck}
                    onChange={setvulnerableemployee}
                  ></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <p style={{ fontWeight: 'bold' }}>Active Employees</p>
                </Col>
                <Col spna={2}></Col>
                <Col Span={4}>
                  <Checkbox checked={empactivecheck} onChange={setactiveemployee}></Checkbox>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <center>
          <Button type='primary' onClick={() => handleSave()}>
            {!loader ? (
              'Save'
            ) : (
              <Space size='middle'>
                <Spin size='small' />
              </Space>
            )}
          </Button>
        </center>
      </div>
    </>
  );
};

export default Profile;
